import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import './style.scss'

function Loading({ size = 'Large' }) {
  let sizePx
  switch (size) {
    case 'Small':
      sizePx = 50
      break
    case 'Medium':
      sizePx = 100
      break
    default:
      sizePx = 150
  }
  return (
    <div id="components_loading">
      <CircularProgress className="spinner" size={sizePx} />
    </div>
  )
}

Loading.propTypes = {
  size: PropTypes.oneOf(['Large', 'Medium', 'Small']),
}

export default Loading
