import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Loading from './loading/loading'
import defaultTableOptions from '../helpers/table'
import api from '../helpers/api'

const columns = [
  {
    name: 'nom',
    label: 'Nom',
    options: {
      setCellHeaderProps: () => ({
        style: {
          width: '40%',
        },
      }),
    },
  },
  {
    name: 'capacite',
    label: 'Capacité',
    options: {
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          width: '20%',
          textAlign: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
  {
    name: 'coutBase',
    label: 'Coût de base',
    options: {
      sort: false,
      customBodyRender: (value) => `${value} $`,
      setCellHeaderProps: () => ({
        style: {
          width: '20%',
          textAlign: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
  {
    name: 'coutParNuit',
    label: 'Coût nuit/personne',
    options: {
      sort: false,
      customBodyRender: (value) => `${value} $`,
      setCellHeaderProps: () => ({
        style: {
          width: '20%',
          textAlign: 'center',
        },
      }),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
]

const options = {
  ...defaultTableOptions,
  sort: false,
  sortOrder: {
    name: 'nom',
    direction: 'asc',
  },
}

function Chalets() {
  const [chalets, setChalets] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    api.get('/chalets').then(({ data }) => {
      if (isMounted) {
        setChalets(data)
        setLoading(false)
      }
    })
    return () => { isMounted = false }
  }, [])

  return (
    <>
      {loading && <Loading size="Medium" />}
      {!loading && (
      <MUIDataTable
        data={chalets}
        columns={columns}
        options={options}
      />
      )}
    </>
  )
}

export default Chalets
