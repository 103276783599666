import React, { createContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import storage from '../helpers/storage'

export const AppContext = createContext(null)

function isPublicUrl(url) {
  return /^(\/login$|\/nouveau-mot-de-passe\/.*|\/mot-passe-oublie$)/.test(url)
}

export function AppProvider({ children }) {
  const user = storage.getUser()
  const [currentCalendarDate, setCurrentCalendarDate] = useState(moment().format('YYYY-MM-DD'))
  const location = useLocation()

  const contents = useMemo(() => {
    if (!user && !isPublicUrl(location.pathname)) {
      const original = location.pathname
      return <Navigate to={`/login?${original !== '/' ? `original=${encodeURIComponent(location.pathname)}` : ''}`} />
    }
    return children
  }, [user, location])

  const context = useMemo(() => ({
    user,
    currentCalendarDate,
    setCurrentCalendarDate,
  }), [user])

  return (
    <AppContext.Provider value={context}>
      {contents}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
