import React from 'react'
import { TextField } from '@mui/material'

function Input(props) {
  return (
    <TextField
      {...props}
      fullWidth
      variant="outlined"
      size="small"
      margin="normal"
    />
  )
}

export default Input
