import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { List } from '@mui/material'
import { chain } from 'lodash'
import { MenuItem, ITEM_SHAPE } from './menu-item'
import { AppContext } from '../hooks/app-context'

function Menu({ items }) {
  const { user: { admin, approbateur } } = useContext(AppContext)

  return (
    <List sx={{ pt: 2 }}>
      {chain(items)
        .filter((item) => (!item.admin && !item.approbateur) || (item.approbateur && approbateur) || (item.admin && admin))
        .map((item) => <MenuItem key={item.id} item={item} />)
        .value()}
    </List>
  )
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(ITEM_SHAPE)).isRequired,
}

export default Menu
