import React, { useEffect } from 'react'
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../components/input'
import api from '../helpers/api'

function NouveauPassword() {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (params.token) {
      api.get(`/auth/validate-reset-token/${params.token}`).catch((err) => {
        navigate(`/login?message=${err.response?.data?.error}`)
      })
    }
  }, [params])

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .min(4, 'Le mot de passe doit avoir une longueur de 4 caractères minimum')
        .required('Champ requis'),
      passwordConfirm: Yup
        .string()
        .required('Champ requis')
        .oneOf([Yup.ref('password'), null], 'Doit être identique au mot de passe'),
    }),
    onSubmit: async (values) => {
      try {
        await api.post(`/auth/set-password/${params.token}`, values)
        navigate('/login?message=Votre mot de passe a été enregistré')
      } catch (err) {
        toast.error(err.response.data.error)
      }
    },
  })

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
      sx={{
        padding: 1,
      }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          elevation={5}
          sx={{
            boxSizing: 'border-box', marginTop: -10, width: { xs: '100%', md: '500px' }, padding: 4,
          }}
        >
          <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>
            Système de réservation des camps Parent
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>Veuillez saisir votre nouveau mot de passe:</Typography>
            <Input
              type="password"
              id="password"
              name="password"
              label="Mot de passe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Input
              type="password"
              id="passwordConfirm"
              name="passwordConfirm"
              label="Mot de passe (confirmation)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passwordConfirm}
              error={Boolean(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
              helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
            />
            <Button disabled={!isEmpty(formik.errors)} type="submit" variant="contained" sx={{ marginTop: 3 }}>Créer mon mot de passe</Button>
          </form>
        </Paper>
      </Grid>
      <ToastContainer
        theme="colored"
        style={{
          fontSize: 14,
          minWidth: 400,
        }}
      />
    </Grid>
  )
}

export default NouveauPassword
