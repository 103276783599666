import React from 'react'
import PropTypes from 'prop-types'
import { TextField as MUITextField } from '@mui/material'
import { get } from 'lodash'

function TextField({
  id, label, type = null, formik, min = null,
}) {
  return (
    <MUITextField
      id={id}
      label={label}
      type={type}
      fullWidth
      margin="dense"
      variant="standard"
      onBlur={formik.handleBlur}
      value={get(formik, ['values', id], '')}
      onChange={formik.handleChange}
      error={get(formik, ['touched', id]) && Boolean(get(formik, ['errors', id]))}
      helperText={get(formik, ['touched', id]) && get(formik, ['errors', id])}
      min={min}
    />
  )
}

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  formik: PropTypes.object.isRequired,
  min: PropTypes.number,
}

export default TextField
