import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Loading from '../components/loading/loading'
import defaultTableOptions from '../helpers/table'
import api from '../helpers/api'

function AVenir() {
  const [reservations, setReservations] = useState()
  const [loading, setLoading] = useState(false)
  const { setTitle } = useOutletContext()
  const navigate = useNavigate()

  const columns = [
    {
      name: 'chalet',
      label: 'Chalet',
      options: {
        sort: false,
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
    {
      name: 'date',
      label: 'Date de réservation',
      options: {
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
    {
      name: 'statut',
      label: 'Statut',
      options: {
        sort: false,
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
  ]

  const options = {
    ...defaultTableOptions,
    sortOrder: {
      name: 'date',
      direction: 'asc',
    },
    onRowClick: (_, { dataIndex }) => navigate(`/reservations/demande/${reservations[dataIndex].id}`),
  }
  options.textLabels.body.noMatch = "Vous n'avez pas de demande de réservation à venir."

  const loadReservations = async () => {
    setLoading(true)
    const { data } = await api.get('/reservations/a-venir')
    setReservations(data)
    setLoading(false)
  }

  useEffect(() => {
    setTitle('Réservations à venir')
    const load = async () => {
      await loadReservations()
    }
    load()
  }, [])

  return (
    <>
      {loading && <Loading size="Medium" />}
      {!loading && (
      <MUIDataTable
        data={reservations}
        columns={columns}
        options={options}
      />
      )}
    </>
  )
}

export default AVenir
