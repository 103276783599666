import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import MUIDataTable from 'mui-datatables'
import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import {
  Add, Cancel, CheckCircle, KeyOff, MoreVert, Recommend, VerifiedUser,
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import { find, get, toLower } from 'lodash'
import md5 from 'md5'
import Loading from '../loading/loading'
import defaultTableOptions from '../../helpers/table'
import FormDialog from './form-dialog'
import api from '../../helpers/api'
import { AppContext } from '../../hooks/app-context'
import './style.scss'

const columns = [
  {
    name: 'id',
    options: { display: false },
  },
  {
    name: 'avatar',
    label: 'Avatar',
    options: {
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          width: 80,
          textAlign: 'center',
        },
      }),
      customBodyRender: (_, { rowData: [,,,, email] }) => <Avatar src={`https://www.gravatar.com/avatar/${md5(toLower(email))}?d=retro`} />,
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
  {
    name: 'prenom',
    label: 'Prénom',
  },
  {
    name: 'nom',
    label: 'Nom',
  },
  {
    name: 'courriel',
    label: 'Courriel',
  },
  {
    name: 'type',
    label: 'Type',
  },
  {
    name: 'admin',
    options: { display: false },
  },
  {
    name: 'approbateur',
    options: { display: false },
  },
  {
    name: 'actif',
    label: 'Statut',
    options: {
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          width: 80,
          textAlign: 'center',
        },
      }),
      customBodyRender: (_, { rowData: [,,,,,,,, actif,, hasPassword] }) => (
        <>
          {actif ? <CheckCircle color="success" /> : <Cancel color="error" />}
          {!hasPassword && <Tooltip title="N'a pas encore créé son mot de passe"><KeyOff /></Tooltip>}
        </>
      ),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
  {
    name: 'roles',
    label: 'Roles',
    options: {
      sort: false,
      setCellHeaderProps: () => ({
        style: {
          width: 80,
          textAlign: 'center',
        },
      }),
      customBodyRender: (_, { rowData: [,,,,,, admin, approbateur] }) => (
        <>
          {admin && <Tooltip title="Administrateur"><VerifiedUser color="primary" /></Tooltip>}
          {approbateur && <Tooltip title="Approbateur"><Recommend color="success" /></Tooltip>}
        </>
      ),
      setCellProps: () => ({
        style: {
          textAlign: 'center',
        },
      }),
    },
  },
  {
    name: 'hasPassword',
    options: {
      display: false,
    },
  },
  {
    name: 'actions',
    label: 'Actions',
    options: {
      sort: false,
      setCellProps: () => ({
        style: {
          textAlign: 'center',
          width: 40,
        },
      }),
    },
  },
]

const options = {
  ...defaultTableOptions,
  sortOrder: {
    name: 'prenom',
    direction: 'asc',
  },
  setRowProps: () => ({
    style: {
      cursor: '',
    },
  }),
}

function Membres() {
  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const [currentUser, setCurrentUser] = React.useState()
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  const { user } = useContext(AppContext)
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    let isMounted = true
    setLoading(true)
    api.get('/users').then(({ data }) => {
      if (isMounted) {
        if (data) {
          setUsers(data)
        }
        setLoading(false)
      }
    })
    return () => { isMounted = false }
  }, [refreshKey])

  const actionsHandler = (e, dataIndex) => {
    setCurrentUser(users[dataIndex])
    setMenuAnchorEl(e.currentTarget)
  }

  const actions = find(columns, (c) => c.name === 'actions')
  if (small) {
    const avatar = find(columns, (c) => c.name === 'avatar')
    avatar.options.display = false
    actions.options.label = ''
    const actif = find(columns, (c) => c.name === 'actif')
    actif.options.setCellProps = () => null
    const roles = find(columns, (c) => c.name === 'roles')
    roles.options.setCellProps = () => null
  }
  actions.options.customBodyRenderLite = (dataIndex) => (!small ? (
    <IconButton onClick={(e) => actionsHandler(e, dataIndex)}>
      <MoreVert />
    </IconButton>
  ) : (<Button onClick={(e) => actionsHandler(e, dataIndex)}>Actions</Button>))

  options.customToolbar = () => <IconButton color="primary" title="Ajouter un utilisateur" onClick={() => setAddDialogOpen(true)}><Add /></IconButton>

  const handleDelete = () => {
    setDeleteDialogOpen(true)
    setMenuAnchorEl(null)
  }

  const confirmDelete = () => {
    const { id, prenom, nom } = currentUser

    api.delete(`/users/${id}`)
      .then(async (res) => {
        if (res) {
          setRefreshKey(refreshKey + 1)
          toast.success(`L'accès de ${prenom} ${nom} a été retiré`, {
            icon: <Cancel />,
          })
        }
      })
      .catch((err) => {
        toast.error(`Erreur: ${get(err, 'response.data.message', err.message)}`)
      })

    setDeleteDialogOpen(false)
  }

  const addUser = async (newUser) => {
    try {
      const { data } = await api.post('/users', newUser)
      setAddDialogOpen(false)
      setRefreshKey(refreshKey + 1)
      toast.success(`Utilisateur ajouté: ${data.prenom} ${data.nom}`)
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(err.message)
      }
    }
  }

  const handleUpdate = () => {
    setMenuAnchorEl(null)
    setEditDialogOpen(true)
  }

  const saveUser = async (updateUser) => {
    try {
      const { data: updatedUser } = await api.patch(`/users/${updateUser.id}`, updateUser)
      setEditDialogOpen(false)
      setRefreshKey(refreshKey + 1)
      toast.success(`Utilisateur modifié: ${updatedUser.prenom} ${updatedUser.nom}`)
    } catch (err) {
      toast.error(`Une erreur est survenue lors de la sauvegarde de l'utilisateur: ${err.message}`)
    }
  }

  const activateUser = async () => {
    try {
      const { data: updatedUser } = await api.patch(`/users/${currentUser.id}`, { actif: true })
      setMenuAnchorEl(null)
      setRefreshKey(refreshKey + 1)
      toast.success(`Utilisateur activé: ${updatedUser.prenom} ${updatedUser.nom}`)
    } catch (err) {
      toast.error(`Une erreur est survenue lors de l'activation de l'utilisateur: ${err.message}`)
    }
  }

  const sendPasswordEmail = async () => {
    try {
      await api.post(`/users/${currentUser.id}/welcome-email`)
      setMenuAnchorEl(null)
      toast.success(`Le courriel de bievenue a été envoyé à ${currentUser.prenom} ${currentUser.nom}`)
    } catch (err) {
      toast.error(`Une erreur est survenue: ${err.message}`)
    }
  }

  const reinitPassword = async () => {
    try {
      await api.post('/auth/reset-password', { courriel: currentUser.courriel })
      setMenuAnchorEl(null)
      toast.success(`Le courriel de réinitialisation de mot de passe a été envoyé à ${currentUser.prenom} ${currentUser.nom}`)
    } catch (err) {
      toast.error(`Une erreur est survenue: ${err.message}`)
    }
  }

  return (
    <>
      {loading && <Loading size="Medium" />}
      {!loading && (
        <div className={!small ? 'table-large' : 'table-small'}>
          <MUIDataTable
            data={users}
            columns={columns}
            options={options}
          />

          {currentUser && (
          <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem onClick={handleUpdate}>Modifier</MenuItem>
            {currentUser.actif && <MenuItem onClick={handleDelete} disabled={currentUser?.id === user.id}>Retirer l'accès</MenuItem>}
            {!currentUser.actif && <MenuItem onClick={activateUser}>Activer l'utilisateur</MenuItem>}
            <MenuItem onClick={sendPasswordEmail} disabled={currentUser.hasPassword}>Envoyer le courriel de bienvenue</MenuItem>
            <MenuItem onClick={reinitPassword}>Envoyer un lien de réinitialisation de mot de passe</MenuItem>
          </Menu>
          )}

          {addDialogOpen
            && (
            <FormDialog
              open={addDialogOpen}
              close={() => setAddDialogOpen(false)}
              save={addUser}
            />
            )}

          {editDialogOpen
            && (
            <FormDialog
              open={editDialogOpen}
              close={() => setEditDialogOpen(false)}
              save={saveUser}
              user={currentUser}
            />
            )}

          {deleteDialogOpen && (
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>Retirer l'accès</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-vous vraiment retirer l'accès de l'utilisateur: {currentUser.prenom} {currentUser.nom}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
              <Button onClick={confirmDelete} color="error" autoFocus>Confirmer</Button>
            </DialogActions>
          </Dialog>
          )}
        </div>
      )}
    </>
  )
}

export default Membres
