import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box, Tab, Tabs,
} from '@mui/material'
import { Add, Cabin, Person } from '@mui/icons-material'
import { useOutletContext } from 'react-router-dom'
import Manuel from '../../components/manual'
import Membres from '../../components/membres'
import Chalets from '../../components/chalets'
import './style.scss'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `admin-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  }
}

function Admin() {
  const [value, setValue] = useState(0)

  const { setTitle } = useOutletContext()

  useEffect(() => {
    setTitle('Administration')
  }, [setTitle])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="basic tabs example">
          <Tab
            classes={{
              root: 'tab-root',
            }}
            icon={<Person />}
            iconPosition="start"
            label="Membres"
            {...a11yProps(0)}
          />
          <Tab
            classes={{
              root: 'tab-root',
            }}
            icon={<Cabin />}
            iconPosition="start"
            label="Chalets"
            {...a11yProps(1)}
          />
          <Tab
            classes={{
              root: 'tab-root',
            }}
            icon={<Add />}
            iconPosition="start"
            label="Ajout manuel de réservation"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Membres />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Chalets />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Manuel />
      </TabPanel>
    </>
  )
}

Admin.propTypes = {}

export default Admin
