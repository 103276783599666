import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {
  Button,
  Card, CardContent, Grid,
} from '@mui/material'
import { find, map, omit } from 'lodash'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../helpers/api'
import Select from './select'
import DatePicker from './date-picker'
import LoadingWrapper from './loading-wrapper'
import TextField from './text-field'
import { DATE_FORMAT } from '../helpers/constants'

const STATUTS = [{
  value: 'nouvelle',
  label: 'Non confirmée',
}, {
  value: 'confirmee',
  label: 'Confirmée',
}]

const validationSchema = yup.object({
  createdBy: yup
    .string('Membre')
    .required('Vous devez choisir au nom de quel membre vous faites la réservation'),
  dateDebut: yup
    .date()
    .required('La date de début est requise'),
  chaletId: yup
    .string('Chalet')
    .required('Vous devez choisir le chalet'),
  nbreNuits: yup
    .number('Nombre de nuits')
    .min(1, 'doit être plus grand ou égal à 1'),
  nbreAdultes: yup
    .number('Nombre d\'adultes')
    .min(1, 'doit être plus grand ou égal à 1'),
  courrielResponsable: yup
    .string('Courriel du responsable')
    .email('doit être une adresse courriel valide'),
})

function Manuel() {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState(null)
  const [chalets, setChalets] = useState(null)
  const navigate = useNavigate()

  const loadUsers = async () => {
    const { data } = await api.get('/users')
    setUsers(data)
  }

  const loadChalets = async () => {
    const { data } = await api.get('/chalets')
    setChalets(data)
  }

  useEffect(() => {
    Promise.all([
      loadUsers(),
      loadChalets(),
    ]).then(() => setLoading(false))
  }, [])

  const formik = useFormik({
    initialValues: {
      nbreNuits: 1,
      nbreAdultes: 1,
      statut: 'confirmee',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { dateDebut, nbreNuits, nbreAdultes } = values
      const details = []
      for (let i = 0; i < nbreNuits; i += 1) {
        details.push({
          day: dateDebut.clone().add(i, 'days').format(DATE_FORMAT),
          count: nbreAdultes,
        })
      }

      const reservation = {
        ...omit(values, ['dateDebut', 'nbreNuits', 'nbreAdultes']),
        details,
      }

      try {
        await api.post('/reservations', reservation)
        toast.success('La demande de réservation a été enregistrée')
        navigate(-1)
      } catch (err) {
        toast.error('Une erreur est survenue pendant l\'enregistrement de la demande.')
      }
    },
  })

  const handleCreatedByChange = (value) => {
    const user = find(users, (createdBy) => createdBy.id === value)
    if (user) {
      formik.setFieldValue('responsable', user.prenom)
      formik.setFieldValue('courrielResponsable', user.courriel)
    }
  }

  return (
    <LoadingWrapper loading={loading}>
      Complétez le formulaire suivant pour créer manuellement une demande de réservation pour l'un des chalets.
      <Card className="reservation-form" raised>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className="form-grid">
              <Grid item xs={12} md={6}>
                <Select
                  id="createdBy"
                  label="Membre"
                  autoFocus
                  formik={formik}
                  items={map(users, (user) => ({ value: user.id, label: `${user.prenom} ${user.nom}` }))}
                  onChange={handleCreatedByChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  id="chaletId"
                  label="Chalet"
                  formik={formik}
                  items={map(chalets, (chalet) => ({ value: chalet.id, label: chalet.nom }))}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                {formik && (
                  <DatePicker
                    id="dateDebut"
                    label="Date de début"
                    disablePast
                    formik={formik}
                  />
                )}
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  id="nbreNuits"
                  label="Nombre de nuits"
                  type="number"
                  min={1}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  id="nbreAdultes"
                  label="Nombre d'adultes"
                  type="number"
                  min={1}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="responsable"
                  label="Responsable"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="courrielResponsable"
                  label="Courriel du responsable"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  id="statut"
                  label="Statut de la demande"
                  formik={formik}
                  items={STATUTS}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="actions">
              <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                <Button
                  sx={{ flex: { xs: 1 } }}
                  variant="outlined"
                  color="info"
                  onClick={() => navigate(-1)}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                <Button sx={{ flex: { xs: 1 } }} variant="contained" color="primary" disabled={!formik.dirty || !formik.isValid} type="submit">Ajouter</Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </LoadingWrapper>
  )
}

export default Manuel
