import React, { useContext, useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Loading from '../components/loading/loading'
import defaultTableOptions from '../helpers/table'
import api from '../helpers/api'
import { AppContext } from '../hooks/app-context'

function Refuse() {
  const [reservations, setReservations] = useState()
  const [loading, setLoading] = useState(false)
  const { setTitle } = useOutletContext()
  const navigate = useNavigate()
  const { user: { approbateur } } = useContext(AppContext)

  const columns = [
    {
      name: 'responsable',
      label: 'Demandeur',
      options: {
        sort: false,
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
    {
      name: 'date',
      label: 'Date de réservation',
      options: {
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
    {
      name: 'chalet',
      label: 'Chalet',
      options: {
        sort: false,
        setCellProps: () => ({
          style: {
            width: '25%',
          },
        }),
      },
    },
    {
      name: 'refusPar',
      label: 'Refusée par',
      options: {
        sort: false,
      },
    },
    {
      name: 'motifRefus',
      label: 'Motif',
      options: {
        sort: false,
      },
    },
  ]

  const options = {
    ...defaultTableOptions,
    sortOrder: {
      name: 'date',
      direction: 'asc',
    },
    onRowClick: (_, { dataIndex }) => navigate(`/reservations/demande/${reservations[dataIndex].id}`),
  }
  options.textLabels.body.noMatch = `${approbateur ? 'Il n\'y a' : 'Vous n\'avez'} pas de demande de réservation refusée.`

  const loadReservations = async () => {
    setLoading(true)
    const { data } = await api.get('/reservations/refuse')
    setReservations(data)
    setLoading(false)
  }

  useEffect(() => {
    setTitle('Demandes de réservation refusées')
    const load = async () => {
      await loadReservations()
    }
    load()
  }, [])

  return (
    <>
      {loading && <Loading size="Medium" />}
      {!loading && (
      <MUIDataTable
        data={reservations}
        columns={columns}
        options={options}
      />
      )}
    </>
  )
}

export default Refuse
