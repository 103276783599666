import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { get, map, noop } from 'lodash'

const ITEMS_SHAPE = PropTypes.shape({
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
})

function Select({
  id, label, formik, autoFocus = false, items, onChange = noop,
}) {
  return (
    <TextField
      autoFocus={autoFocus}
      select
      margin="dense"
      id={id}
      name={id}
      label={label}
      variant="standard"
      fullWidth
      value={get(formik, ['values', id], '')}
      onChange={(e) => {
        formik.handleChange(e)
        onChange(e.target.value)
      }}
      error={get(formik, ['touched', id]) && Boolean(get(formik, ['errors', id]))}
      helperText={get(formik, ['touched', id]) && get(formik, ['errors', id])}
    >
      {map(items, (item) => <MenuItem key={`menuitem-${id}-${item.value}`} value={item.value}>{item.label}</MenuItem>)}
    </TextField>
  )
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  items: PropTypes.arrayOf(ITEMS_SHAPE).isRequired,
  onChange: PropTypes.func,
}

export default Select
