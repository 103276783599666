import { jwtDecode } from 'jwt-decode'
import { omit } from 'lodash'
import moment from 'moment'

const getAccessToken = () => localStorage.getItem('accessToken')
const getRefreshToken = () => localStorage.getItem('refreshToken')

const checkIfAuthExpired = () => {
  try {
    const decoded = jwtDecode(getRefreshToken())
    return decoded.exp < moment().format('X')
  } catch (err) {
    return true
  }
}

const getUser = () => {
  if (checkIfAuthExpired()) {
    return null
  }

  const token = getAccessToken()
  try {
    return omit(jwtDecode(token), ['exp', 'iat'])
  } catch (err) {
    return null
  }
}

const setTokens = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
}

const clear = () => localStorage.clear()

export default {
  getAccessToken,
  getUser,
  getRefreshToken,
  setTokens,
  clear,
}
