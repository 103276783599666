import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { size, chain } from 'lodash'
import { AppContext } from '../hooks/app-context'

export const ITEM_SHAPE = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  icon: PropTypes.object.isRequired,
  admin: PropTypes.bool,
  items: PropTypes.array,
}

const padding = (level) => level * 2 + 1

export function MenuItem({
  item: {
    label, link, icon, items,
  }, level = 0,
}) {
  const { user: { admin, approbateur } } = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    if (link) {
      if (location.pathname.startsWith('/reservations/demande') && link === '/reservations/demande') {
        window.location = link
      } else {
        navigate(link)
      }
    }
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={handleClick}
          selected={location.pathname === link}
          sx={{ pl: padding(level) }}
        >
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
      {size(items) > 0 && (
        <List disablePadding>
          {chain(items)
            .filter((item) => (!item.admin && !item.approbateur) || (item.approbateur && approbateur) || (item.admin && admin))
            .map((item) => <MenuItem key={item.id} item={item} level={level + 1} />)
            .value()}
        </List>
      )}
    </>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape(ITEM_SHAPE).isRequired,
  level: PropTypes.number,
}
