import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Logout } from '@mui/icons-material'
import storage from '../helpers/storage'

function LogoutButton() {
  const navigate = useNavigate()

  const handleLogout = () => {
    storage.clear()
    navigate('/login')
  }

  return (
    <Tooltip title="Déconnexion">
      <IconButton onClick={handleLogout} color="inherit"><Logout /></IconButton>
    </Tooltip>
  )
}

export default LogoutButton
