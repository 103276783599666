import React, { useState, useEffect, useContext } from 'react'
import { Navigate, Outlet, useOutletContext } from 'react-router-dom'
import Loading from './loading/loading'
import { AppContext } from '../hooks/app-context'

function AdminRoute() {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AppContext)
  const context = useOutletContext()

  useEffect(() => {
    if (user) {
      setIsLoading(false)
    }
  }, [user])

  if (isLoading) {
    return <Loading />
  }

  return user.admin ? <Outlet context={context} /> : <Navigate to="/unauthorized" />
}

export default AdminRoute
