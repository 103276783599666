import {
  AddCircle,
  BookmarkBorder,
  CalendarMonth,
  Home,
  AccessTime,
  Settings,
  ThumbDown,
  Menu as MenuIcon,
  Event,
} from '@mui/icons-material'
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Toolbar,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import md5 from 'md5'
import { toLower } from 'lodash'
import LogoutButton from './components/logout-button'
import Menu from './components/menu'
import 'react-toastify/dist/ReactToastify.css'
import { AppContext } from './hooks/app-context'
import vars from './helpers/vars'

const drawerWidth = 240

function App() {
  const [drawerOpened, setDrawerOpened] = useState()
  const [title, setTitle] = useState('')
  const { user } = useContext(AppContext)
  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('md'))

  const location = useLocation()
  useEffect(() => {
    setDrawerOpened(false)
  }, [location])

  return (
    <Box sx={{ boxSizing: 'border-box', display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Toolbar>
          <IconButton onClick={() => setDrawerOpened(true)} sx={{ display: { xs: 'flex', md: 'none' } }}><MenuIcon sx={{ color: 'white ' }} /></IconButton>
          <Typography sx={{ display: { xs: 'none', md: 'block' } }}>{title}</Typography>
        </Toolbar>
        <Toolbar sx={{ gap: 1 }}>
          <Avatar src={`https://www.gravatar.com/avatar/${md5(toLower(user.courriel))}?d=retro`} sx={{ height: 30, width: 30 }} />
          <Typography sx={{ display: { xs: 'none', md: 'block' } }}>{user.prenom} {user.nom}</Typography>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={large ? 'permanent' : 'temporary'}
        anchor="left"
        open={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        PaperProps={{
          elevation: 8,
          sx: { backgroundColor: grey[100] },
        }}
      >
        <AppBar
          position="static"
          sx={{ width: `${drawerWidth - 1}px` }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, color: 'white' }}
            >
              <Link
                to="/"
                style={{
                  display: 'flex', alignItems: 'center', color: 'white', textDecoration: 'none',
                }}
              >
                <Home sx={{ marginRight: 1 }} />
                Famille Parent
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
        <Divider />
        <Menu items={[
          {
            id: 'admin',
            label: 'Administration',
            link: '/admin',
            icon: <Settings />,
            admin: true,
          },
          {
            id: 'calendrier',
            label: 'Calendrier',
            link: '/calendrier',
            icon: <CalendarMonth />,
          },
          {
            id: 'reservations',
            label: 'Réservations',
            icon: <BookmarkBorder />,
            items: [
              {
                id: 'demande-reservation',
                label: 'Nouvelle demande',
                link: '/reservations/demande',
                icon: <AddCircle />,
              },
              {
                id: 'reservations-non-confirmees',
                label: 'Non confirmées',
                link: '/reservations/non-confirme',
                icon: <AccessTime />,
                approbateur: true,
              },
              {
                id: 'reservations-refusees',
                label: 'Refusées',
                link: '/reservations/refuse',
                icon: <ThumbDown />,
              },
              {
                id: 'reservations-a-venir',
                label: 'Mes réservations à venir',
                link: '/reservations/a-venir',
                icon: <Event />,
              }],
          },
        ]}
        />
        <Divider />
        <div className="versionNumber">{vars.version}</div>
      </Drawer>
      <Box
        component="main"
        sx={{
          flex: 1, bgcolor: 'background.default', p: 3,
        }}
      >
        <Toolbar />
        <Outlet context={{ setTitle }} />
      </Box>
      <ToastContainer
        theme="colored"
        style={{
          fontSize: 14,
          minWidth: 400,
        }}
      />
    </Box>
  )
}

export default App
