import axios from 'axios'
import { set } from 'lodash'
import storage from './storage'
import vars from './vars'

const instance = axios.create({
  baseURL: vars.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  (config) => {
    const token = storage.getAccessToken()
    if (token) {
      set(config, 'headers.Authorization', `Bearer ${token}`)
    }
    return config
  },
  (error) => Promise.reject(error),
)

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config

    if (originalConfig.url !== '/auth/login' && originalConfig.url !== '/auth/refresh' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = await instance.post('/auth/refresh', {
            refreshToken: storage.getRefreshToken(),
          })

          const { accessToken, refreshToken } = rs.data
          storage.setTokens(accessToken, refreshToken)

          return instance(originalConfig)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    } else if (originalConfig.url === '/auth/refresh') {
      storage.clear()
    }

    return Promise.reject(err)
  },
)

export default instance
