import React, {
  useContext, useEffect, useState,
} from 'react'
import { map } from 'lodash'
import moment from 'moment'
import { useOutletContext, useNavigate } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import { DATE_FORMAT } from '../../helpers/constants'
import api from '../../helpers/api'
import { AppContext } from '../../hooks/app-context'
import './style.scss'

function Reservations() {
  const navigate = useNavigate()
  const { currentCalendarDate, setCurrentCalendarDate } = useContext(AppContext)
  const [visibleDates, setVisibleDates] = useState(null)
  const [events, setEvents] = useState([])

  const { setTitle } = useOutletContext()

  useEffect(() => {
    setTitle('Calendrier')
  }, [setTitle])

  useEffect(() => {
    if (visibleDates) {
      const current = visibleDates.view.getCurrentData()
      setCurrentCalendarDate(moment(current.viewTitle, 'MMMM YYYY').format('YYYY-MM-DD'))
      const { start, end } = visibleDates
      const load = async () => {
        const from = moment(start, DATE_FORMAT).format('X')
        const to = moment(end, DATE_FORMAT).format('X')
        const { data } = await api.get(`/reservations?from=${from}&to=${to}`)
        setEvents(map(data, (e) => ({
          ...e,
          className: e.confirme ? 'confirme' : 'non-confirme',
        })))
      }
      load()
    }
  }, [visibleDates])

  const openReservation = ({ event: { id } }) => {
    navigate(`/reservations/demande/${id}`)
  }

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        initialDate={currentCalendarDate}
        datesSet={setVisibleDates}
        locale={frLocale}
        firstDay={0}
        events={events}
        eventClick={openReservation}
        height="100%"
        dayCellContent={({ date: eventDate, isPast }) => {
          let contents = `<div>${eventDate.getDate()}</div>`
          if (!isPast) {
            const date = moment(eventDate, DATE_FORMAT).format(DATE_FORMAT)
            contents += `<div><button class="dayAddButton" onclick="window.location='/reservations/demande?date=${date}'">+</button></div>`
          }
          return { html: contents }
        }}
      />
    </div>
  )
}

export default Reservations
