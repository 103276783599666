import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

function Unauthorized() {
  const { setTitle } = useOutletContext()

  useEffect(() => {
    setTitle('Accès restreint')
  }, [setTitle])

  return (
    <>
      Cette page est restreinte et vous n'avez pas les droits nécessaires pour y accéder.
    </>
  )
}

export default Unauthorized
