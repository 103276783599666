import React from 'react'
import PropTypes from 'prop-types'
import { LocalizationProvider, DatePicker as MUIDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { get } from 'lodash'
import { DATE_FORMAT } from '../helpers/constants'
import 'moment/locale/fr-ca'

function DatePicker({
  id, label, disablePast = false, formik,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr-ca">
      <MUIDatePicker
        label={label}
        disablePast={disablePast}
        inputFormat={DATE_FORMAT}
        value={get(formik, ['values', id], null)}
        onChange={(value) => formik.setFieldValue(id, value)}
        error={get(formik, ['touched', id]) && Boolean(get(formik, ['errors', id]))}
        slotProps={{
          textField: {
            id,
            fullWidth: true,
            margin: 'dense',
            variant: 'standard',
            onBlur: formik.handleBlur,
            helperText: get(formik, ['touched', id]) && get(formik, ['errors', id]),
          },
        }}
      />
    </LocalizationProvider>
  )
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disablePast: PropTypes.bool,
  formik: PropTypes.object.isRequired,
}

export default DatePicker
