import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Input from '../components/input'
import api from '../helpers/api'
import storage from '../helpers/storage'

function Login() {
  const [message, setMessage] = useState()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setMessage(searchParams.get('message'))
  }, [])

  const formik = useFormik({
    initialValues: {
      courriel: '',
      password: '',
    },
    validationSchema: Yup.object({
      courriel: Yup.string().email('Adresse courriel invalide').required('Champ requis'),
      password: Yup.string().required('Champ requis'),
    }),
    onSubmit: async (values) => {
      try {
        const { data: { accessToken, refreshToken } } = await api.post('/auth/login', values)
        storage.setTokens(accessToken, refreshToken)
        const url = searchParams.get('original') || '/'
        navigate(decodeURIComponent(url))
      } catch (err) {
        if (err.response && err.response.status === 401) {
          toast.error('Les informations de connexion sont invalides.')
        } else {
          toast.error(`Erreur inattendue: ${err.message}`)
        }
      }
    },
  })

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
      sx={{
        padding: 1,
      }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          elevation={5}
          sx={{
            boxSizing: 'border-box', marginTop: -10, width: { xs: '100%', md: '500px' }, padding: 4,
          }}
        >
          <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>
            Système de réservation des camps Parent
          </Typography>
          {message && (
          <Typography
            color="white"
            sx={{
              padding: '0.5em 1em', backgroundColor: '#689f38', marginBottom: 2, borderRadius: 1.5,
            }}
          >
            {message}
          </Typography>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>Veuillez saisir vos informations pour vous connecter:</Typography>
            <Input
              id="courriel"
              name="courriel"
              label="Courriel"
              autoComplete="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courriel}
              error={Boolean(formik.touched.courriel && formik.errors.courriel)}
              helperText={formik.touched.courriel && formik.errors.courriel}
            />
            <Input
              type="password"
              id="password"
              name="password"
              label="Mot de passe"
              autoComplete="current-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button disabled={!formik.dirty || !isEmpty(formik.errors)} type="submit" variant="contained" sx={{ marginTop: 3 }}>Connexion</Button>
            <Button type="button" variant="outlined" sx={{ marginTop: 3, marginLeft: 1 }} onClick={() => navigate('/mot-passe-oublie')}>Mot de passe oublié</Button>
          </form>
        </Paper>
      </Grid>
      <ToastContainer
        theme="colored"
        style={{
          fontSize: 14,
          minWidth: 400,
        }}
      />
    </Grid>
  )
}

export default Login
