import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import Admin from './pages/admin'
import AdminRoute from './components/admin-route'
import Unauthorized from './pages/unauthorized'
import Login from './pages/login'
import Reservations from './pages/reservations'
import ReservationForm from './pages/reservation-form'
import { AppProvider } from './hooks/app-context'
import NonConfirme from './pages/non-confirme'
import Refuse from './pages/refuse'
import NouveauPassword from './pages/nouveau-password'
import ReinitPassword from './pages/reinit-password'
import AVenir from './pages/a-venir'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <AppProvider>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Navigate to="/calendrier" />} />
          <Route path="/admin" element={<AdminRoute />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="/calendrier" element={<Reservations />} />
          <Route path="/reservations/demande" element={<ReservationForm />}>
            <Route path=":id" element={<ReservationForm />} />
          </Route>
          <Route path="/reservations/non-confirme" element={<NonConfirme />} />
          <Route path="/reservations/refuse" element={<Refuse />} />
          <Route path="/reservations/a-venir" element={<AVenir />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/mot-passe-oublie" element={<ReinitPassword />} />
        <Route path="/nouveau-mot-de-passe/:token" element={<NouveauPassword />} />
      </Routes>
    </AppProvider>
  </BrowserRouter>,
)

reportWebVitals()
