import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Loading from '../components/loading/loading'
import defaultTableOptions from '../helpers/table'
import api from '../helpers/api'

const columns = [
  {
    name: 'responsable',
    label: 'Demandeur',
    options: {
      sort: false,
      setCellProps: () => ({
        style: {
          width: '33%',
        },
      }),
    },
  },
  {
    name: 'date',
    label: 'Date de réservation',
    options: {
      setCellProps: () => ({
        style: {
          width: '33%',
        },
      }),
    },
  },
  {
    name: 'chalet',
    label: 'Chalet',
    options: {
      sort: false,
    },
  },
]

function NonConfirme() {
  const [reservations, setReservations] = useState()
  const [loading, setLoading] = useState(false)
  const { setTitle } = useOutletContext()
  const navigate = useNavigate()

  const options = {
    ...defaultTableOptions,
    sortOrder: {
      name: 'date',
      direction: 'asc',
    },
    onRowClick: (_, { dataIndex }) => navigate(`/reservations/demande/${reservations[dataIndex].id}`),
  }
  options.textLabels.body.noMatch = 'Il n\'y a pas de demande de réservation non confirmée pour le moment.'

  const loadReservations = async () => {
    setLoading(true)
    const { data } = await api.get('/reservations/non-confirme')
    setReservations(data)
    setLoading(false)
  }

  useEffect(() => {
    setTitle('Demandes de réservation non confirmées')

    const load = async () => {
      await loadReservations()
    }
    load()
  }, [])

  return (
    <>
      {loading && <Loading size="Medium" />}
      {!loading && (
      <MUIDataTable
        data={reservations}
        columns={columns}
        options={options}
      />
      )}
    </>
  )
}

export default NonConfirme
