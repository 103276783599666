import React from 'react'
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Input from '../components/input'
import api from '../helpers/api'

function Login() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      courriel: '',
    },
    validationSchema: Yup.object({
      courriel: Yup.string().email('Adresse courriel invalide').required('Champ requis'),
    }),
    onSubmit: async (values) => {
      try {
        await api.post('/auth/reset-password', values)
        navigate('/login?message=Un lien pour réinitialiser votre mot de passe, qui sera valide pendant 15 minutes, vous a été envoyé par courriel.')
      } catch (err) {
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data?.message)
        } else {
          toast.error(`Erreur inattendue: ${err.message}`)
        }
      }
    },
  })

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
      sx={{
        padding: 1,
      }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          elevation={5}
          sx={{
            boxSizing: 'border-box', marginTop: -10, width: { xs: '100%', md: '500px' }, padding: 4,
          }}
        >
          <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>
            Mot de passe oublié
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>Veuillez saisir votre adresse courriel:</Typography>
            <Input
              id="courriel"
              name="courriel"
              label="Courriel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.courriel}
              error={Boolean(formik.touched.courriel && formik.errors.courriel)}
              helperText={formik.touched.courriel && formik.errors.courriel}
            />
            <Button disabled={!formik.dirty || !isEmpty(formik.errors)} type="submit" variant="contained" sx={{ marginTop: 3 }}>Réinitialiser mon mot de passe</Button>
            <Button type="button" variant="outlined" sx={{ marginTop: 3, marginLeft: 1 }} onClick={() => navigate('/login')}>Annuler</Button>
          </form>
        </Paper>
      </Grid>
      <ToastContainer
        theme="colored"
        style={{
          fontSize: 14,
          minWidth: 400,
        }}
      />
    </Grid>
  )
}

export default Login
