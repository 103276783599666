const defaultTableOptions = {
  download: false,
  print: false,
  pagination: false,
  viewColumns: false,
  search: false,
  filter: false,
  selectableRows: 'none',
  setRowProps: () => ({
    style: {
      cursor: 'pointer',
    },
  }),
  textLabels: {
    body: {
      noMatch: 'Désolé, aucun enregistrement trouvé',
      toolTip: 'Trier',
    },
    pagination: {
      next: 'Page suivante',
      previous: 'Page précédente',
      rowsPerPage: 'Rangées par page:',
      displayRows: 'de',
    },
    toolbar: {
      search: 'Recherche',
      downloadCsv: 'Télécharger le CSV',
      print: 'Imprimer',
      viewColumns: 'Colonnes',
      filterTable: 'Filtrer',
    },
    filter: {
      all: 'Tous',
      title: 'FILTRES',
      reset: 'RÉINITIALISER',
    },
    viewColumns: {
      title: 'Colonnes',
      titleAria: 'Voir/cacher des colonnes',
    },
    selectedRows: {
      text: 'rangée(s) sélectionnée(s)',
      delete: 'Supprimer',
      deleteAria: 'Supprimer les rangées sélectionnées',
    },
  },
}

export default defaultTableOptions
