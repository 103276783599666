import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  prenom: yup
    .string('Prénom')
    .required('Le nom est requis'),
  nom: yup
    .string('Nom')
    .required('Le nom est requis'),
  courriel: yup
    .string('Courriel')
    .email('L\'adresse courriel est invalide')
    .required('L\'adresse courriel est requise'),
  type: yup
    .string('Type')
    .required('Le type est requis'),
})

const DEFAULT_USER = {
  id: null,
  prenom: '',
  nom: '',
  courriel: '',
  type: '',
  admin: false,
  approbateur: false,
}

function FormDialog({
  open,
  close,
  user = DEFAULT_USER,
  save,
}) {
  const formik = useFormik({
    initialValues: user || {
      prenom: '',
      nom: '',
      courriel: '',
      type: '',
      admin: false,
      approbateur: false,
    },
    validationSchema,
    onSubmit: (values) => {
      save(values)
    },
  })

  const title = user.id ? 'Modifier un utilisateur' : 'Ajouter un nouvel utilisateur'
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Complétez les informations suivantes pour {user.id ? 'modifier l\'' : 'ajouter un nouvel '}utilisateur.
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            autoFocus
            margin="dense"
            id="prenom"
            label="Prénom"
            fullWidth
            variant="standard"
            value={formik.values.prenom}
            onChange={formik.handleChange}
            error={formik.touched.prenom && Boolean(formik.errors.prenom)}
            helperText={formik.touched.prenom && formik.errors.prenom}
          />
          <TextField
            margin="dense"
            id="nom"
            label="Nom"
            fullWidth
            variant="standard"
            value={formik.values.nom}
            onChange={formik.handleChange}
            error={formik.touched.nom && Boolean(formik.errors.nom)}
            helperText={formik.touched.nom && formik.errors.nom}
          />
          <TextField
            margin="dense"
            id="courriel"
            label="Courriel"
            type="email"
            fullWidth
            variant="standard"
            value={formik.values.courriel}
            disabled={!!user.id}
            onChange={formik.handleChange}
            error={formik.touched.courriel && Boolean(formik.errors.courriel)}
            helperText={formik.touched.courriel && formik.errors.courriel}
          />
          <TextField
            margin="dense"
            id="type"
            name="type"
            label="Type"
            select
            fullWidth
            variant="standard"
            value={formik.values.type}
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          >
            <MenuItem value="Parent">Parent</MenuItem>
            <MenuItem value="Petit-enfant">Petit-enfant</MenuItem>
          </TextField>
          <br />
          <br />
          <FormControlLabel
            className="admin-switch"
            control={<Switch id="admin" name="admin" checked={formik.values.admin} onChange={formik.handleChange} color="primary" />}
            label="Administrateur"
          />
          <br />
          <FormControlLabel
            className="approbateur-switch"
            control={<Switch id="approbateur" name="approbateur" checked={formik.values.approbateur} onChange={formik.handleChange} color="primary" />}
            label="Approbateur"
          />
          <DialogActions>
            <Button onClick={close}>Annuler</Button>
            <Button type="submit">{user.id ? 'Modifier' : 'Ajouter'}</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  user: PropTypes.object,
}

export default FormDialog
