import React from 'react'
import PropTypes from 'prop-types'
import Loading from './loading/loading'

function LoadingWrapper({ loading, children }) {
  if (loading) {
    return <Loading />
  }
  return children
}

LoadingWrapper.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default LoadingWrapper
