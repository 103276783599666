import React, {
  useContext, useEffect, useState,
} from 'react'
import { useFormik } from 'formik'
import {
  Button,
  Card,
  Grid,
  TextField,
  MenuItem,
  CardContent,
  Divider,
  Typography,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  chain,
  find,
  get,
  isEmpty,
  map,
  size,
  some,
  sumBy,
  toNumber,
} from 'lodash'
import moment from 'moment'
import {
  useLocation, useNavigate, useOutletContext, useParams,
} from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DATE_FORMAT } from '../../helpers/constants'
import Loading from '../../components/loading/loading'
import { AppContext } from '../../hooks/app-context'
import api from '../../helpers/api'
import './style.scss'

const validateForm = async (values) => {
  const errors = {}

  if (!values.responsable) {
    errors.responsable = 'Vous devez entrer le nom du responsable'
  }
  if (!values.courrielResponsable) {
    errors.courrielResponsable = 'Vous devez entrer le courriel du responsable'
  }
  if (!(await yup.string().email().isValid(values.courrielResponsable))) {
    errors.courrielResponsable = 'Le courriel doit être valide'
  }
  if (!values.chalet) {
    errors.chalet = 'Vous devez choisir le chalet'
  }
  if (!values.dateDebut) {
    errors.dateDebut = 'La date de début de la réservation est requise'
  } else if (!values.dateDebut.isValid()) {
    errors.dateDebut = 'Date invalide'
  } else if (values.dateDebut < moment().startOf('day')) {
    errors.dateDebut = 'Date antérieure à aujourd\'hui'
  }
  if (!values.nbreNuits) {
    errors.nbreNuits = 'Saisissez un nombre de nuits'
  } else if (values.nbreNuits <= 0) {
    errors.nbreNuits = 'Doit être supérieur à 0'
  }
  if (values.details) {
    if (some(values.details, ({ count }) => count <= 0)) {
      errors.details = 'Vous devez entrer un nombre minimum d\'une personne par jour'
    }
  }

  return errors
}

function getChalet(chalets, chaletId) {
  return find(chalets, (chalet) => chalet.id === chaletId)
}

function getReservationString(chalets, { chalet: chaletId, responsable, dateDebut }) {
  const { nom: nomChalet } = getChalet(chalets, chaletId)
  return `${responsable} pour le chalet du ${nomChalet} le ${dateDebut.format('LL')}`
}

function ReservationForm() {
  const [chalets, setChalets] = useState([])
  const [allDaysIdentical, setAllDaysIdentical] = useState(true)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reservation, setReservation] = useState(null)
  const [readOnly, setReadOnly] = useState(false)
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const [refusDialogOpen, setRefusDialogOpen] = useState(false)
  const { user } = useContext(AppContext)
  const [motifRefus, setMotifRefus] = useState('')
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const theme = useTheme()
  const large = useMediaQuery(theme.breakpoints.up('md'))

  const formik = useFormik({
    initialValues: {
      responsable: user.prenom,
      courrielResponsable: user.courriel,
      chalet: null,
      details: null,
      dateDebut: '',
      nbreNuits: '',
      message: '',
    },
    validate: validateForm,
    onSubmit: async ({
      responsable, courrielResponsable, chalet, details, message,
    }) => {
      if (reservation) {
        const update = {
          responsable,
          courrielResponsable,
          chaletId: chalet,
          details,
          message,
        }

        try {
          const res = await api.patch(`/reservations/${reservation.id}`, update)
          if (res.status === 200) {
            toast.success(`La demande de réservation de ${getReservationString(chalets, formik.values)} été enregistrée`)
          } else {
            toast.info('Aucun changement apporté à la réservation')
          }
          navigate(-1)
        } catch (err) {
          toast.error(`Une erreur est survenue: ${get(err, 'response.data', err.message)}`)
          navigate(-1)
        }
      } else {
        const record = {
          responsable,
          courrielResponsable,
          chaletId: chalet,
          details,
          message,
        }

        try {
          await api.post('/reservations', record)
          toast.success('Votre demande de réservation a été soumise')
          navigate(-1)
        } catch (err) {
          toast.error('Une erreur est survenue pendant l\'envoi de votre demande.')
        }
      }
    },
  })

  const { setTitle } = useOutletContext()

  useEffect(() => {
    setTitle(reservation && !readOnly ? 'Modification d\'une demande de réservation' : 'Demande de réservation')
    if (reservation && !user.admin && !user.approbateur) {
      if (reservation.membreId !== user.id || reservation.statut !== 'nouvelle') {
        setReadOnly(true)
      }
    }
  }, [setTitle, reservation, readOnly])

  useEffect(() => {
    const qs = queryString.parse(location.search)
    if (qs.date) {
      formik.setFieldValue('dateDebut', moment(qs.date, DATE_FORMAT))
    }
  }, [location])

  useEffect(() => {
    if (params.id && dataLoaded) {
      const load = async () => {
        const { id } = params
        const { data } = await api.get(`/reservations/${id}`)
        setReservation(data)

        const {
          responsable,
          courrielResponsable,
          chaletId: chalet,
          details,
          message,
        } = data
        const dateDebut = moment(chain(details).minBy('day').get('day').value(), DATE_FORMAT)
        const dateFin = moment(chain(details).maxBy('day').get('day').value(), DATE_FORMAT).add(1, 'day')

        formik.setValues({
          responsable,
          courrielResponsable,
          chalet,
          dateDebut,
          nbreNuits: dateFin.diff(dateDebut, 'days'),
          details,
          message,
        })
        setLoading(false)
      }
      load()
    }
  }, [params, dataLoaded])

  useEffect(() => {
    api.get('/chalets').then(({ data }) => {
      setChalets(data)
      setDataLoaded(true)
      if (!params.id) {
        setLoading(false)
      }
    })
  }, [params])

  useEffect(() => {
    formik.validateForm()
  }, [])

  const handleNbreNuits = (value, newDateDebut) => {
    const dateDebut = newDateDebut || formik.values.dateDebut
    const details = []
    const currentDetails = formik.values.details
    if (value <= 0 || !dateDebut) {
      formik.setFieldValue('details', null)
    } else {
      for (let i = 0; i < value; i += 1) {
        let count = 0
        if (currentDetails) {
          if (allDaysIdentical && currentDetails[0]) {
            count = currentDetails[0].count
          } else if (currentDetails[i]) {
            count = currentDetails[i].count
          }
        }
        details.push({
          day: dateDebut.clone().add(i, 'days').format(DATE_FORMAT),
          count,
        })
      }
      formik.setFieldValue('details', details)
    }
  }

  const setDayCount = (day, value) => {
    const { details } = formik.values

    if (details && size(details)) {
      const newDetails = map(details, (d) => (allDaysIdentical || d.day === day
        ? { ...d, count: value }
        : d))

      formik.setFieldValue('details', newDetails)
    }
  }

  useEffect(() => {
    const { details } = formik.values
    if (details && size(details) && allDaysIdentical) {
      setDayCount(formik.values.dateDebut, formik.values.details[0].count)
    }
  }, [allDaysIdentical])

  const nbreNuitsHelperText = () => {
    if (!formik.values.dateDebut) {
      return 'Saisissez une date de début d\'abord'
    }
    if (formik.touched.nbreNuits && formik.errors.nbreNuits) {
      return formik.errors.nbreNuits
    }
    return ''
  }

  const handleRefus = async () => {
    setRefusDialogOpen(false)
    await api.patch(`/reservations/${reservation.id}/refusee`, { motifRefus })
    toast.success(`La demande de réservation de ${getReservationString(chalets, formik.values)} a été refusée`)
    navigate(-1)
  }

  const confirmer = async () => {
    await api.patch(`/reservations/${reservation.id}/confirmee`)
    toast.success(`La demande de réservation de ${getReservationString(chalets, formik.values)} a été confirmée`)
    navigate(-1)
  }

  const openRefusDialog = () => {
    setMotifRefus('')
    setRefusDialogOpen(true)
  }

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }

  const handleDelete = async () => {
    await api.delete(`/reservations/${reservation.id}`)
    toast.success(`La demande de réservation de ${getReservationString(chalets, formik.values)} a été supprimée`)
    navigate(-1)
  }

  function getTotalReservation() {
    const { details } = formik.values
    if (isEmpty(details)) {
      return ''
    }
    const nuitsPersonne = sumBy(details, (row) => toNumber(row.count))
    const { nom, coutBase, coutParNuit } = getChalet(chalets, formik.values.chalet)
    const total = nuitsPersonne * coutParNuit + coutBase
    return (
      <>
        {total}$
        <Typography variant="caption" sx={{ marginLeft: 2 }}>
          Coût de base du {nom}: {coutBase}$ + {nuitsPersonne} nuits/personne * {coutParNuit}$
        </Typography>
      </>
    )
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && (
      <>{!readOnly && `Complétez le formulaire suivant pour ${reservation ? 'modifier' : 'créer'} une demande de réservation pour l'un des chalets.`}
        <Card className="reservation-form" raised>
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} className="form-grid">
                {reservation && reservation.statut === 'refusee' && (
                <Grid item xs={12} className="statut-demande">
                  <Typography component="h3" className="refusee"><span>Refusée</span> par {reservation.updatedByUser.prenom} le {moment(reservation.updatedAt, DATE_FORMAT).format('LL')}</Typography>
                  <span>Motif: {reservation.motifRefus}</span>
                  <Divider />
                </Grid>
                )}
                {reservation && reservation.statut === 'confirmee' && (
                <Grid item xs={12} className="statut-demande">
                  <Typography component="h3" className="confirmee"><span>Confirmée</span> par {reservation.updatedByUser.prenom} le {moment(reservation.updatedAt, DATE_FORMAT).format('LL')}</Typography>
                  <Divider />
                </Grid>
                )}
                <Grid item xs={12} md={3}>
                  <TextField
                    id="responsable"
                    autoFocus
                    label="Responsable de la réservation"
                    fullWidth
                    disabled={readOnly}
                    variant="standard"
                    value={formik.values.responsable || ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.responsable && Boolean(formik.errors.responsable)}
                    helperText={formik.touched.responsable && formik.errors.responsable ? formik.errors.responsable : ''}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="courrielResponsable"
                    label="Courriel du responsable de la réservation"
                    fullWidth
                    disabled={readOnly}
                    variant="standard"
                    value={formik.values.courrielResponsable || ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.courrielResponsable && Boolean(formik.errors.courrielResponsable)}
                    helperText={(formik.touched.courrielResponsable && formik.errors.courrielResponsable) || 'Celui qui recevra les notifications'}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    name="chalet"
                    select
                    label="Chalet"
                    fullWidth
                    disabled={readOnly}
                    variant="standard"
                    defaultValue=""
                    value={formik.values.chalet || ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.chalet && Boolean(formik.errors.chalet)}
                    helperText={formik.errors.chalet}
                  >
                    {chalets.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {formik.values.chalet && (
                <Grid item xs={6} md={2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Date de début"
                      disabled={readOnly}
                      disablePast
                      inputFormat={DATE_FORMAT}
                      value={formik.values.dateDebut || null}
                      onChange={async (value) => {
                        const res = await formik.setFieldValue('dateDebut', value)
                        if (!res.dateDebut) {
                          handleNbreNuits(formik.values.nbreNuits, value)
                        }
                      }}
                      error={formik.touched.dateDebut && Boolean(formik.errors.dateDebut)}
                      slotProps={{
                        textField: {
                          id: 'dateDebut',
                          fullWidth: true,
                          variant: 'standard',
                          onBlur: formik.handleBlur,
                          helperText: formik.touched.dateDebut && formik.errors.dateDebut,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                )}
                {formik.values.chalet && formik.values.dateDebut && (
                <Grid item xs={6} md={2}>
                  <TextField
                    id="nbreNuits"
                    label="Nombre de nuits"
                    type="number"
                    fullWidth
                    variant="standard"
                    disabled={readOnly || !formik.values.dateDebut}
                    onBlur={formik.handleBlur}
                    value={formik.values.nbreNuits || ''}
                    onChange={(event) => {
                      const { value } = event.target
                      formik.setFieldValue('nbreNuits', value)
                      handleNbreNuits(value)
                    }}
                    error={formik.touched.nbreNuits && Boolean(formik.errors.nbreNuits)}
                    helperText={nbreNuitsHelperText()}
                  />
                </Grid>
                )}
                {(formik.values.details && formik.values.chalet) && (
                <fieldset className="reservation-form-details">
                  <legend>Détails de la réservation</legend>
                  <Grid container spacing={2}>
                    {map(formik.values.details, ({ day, count }, idx) => (
                      <React.Fragment key={day}>
                        <Grid item md={2} xs={6}>{day}</Grid>
                        <Grid item md={3} xs={6}>
                          <TextField
                            key={day}
                            label="Nombre d'adultes"
                            type="number"
                            variant="standard"
                            value={count}
                            disabled={readOnly || (idx > 0 && allDaysIdentical)}
                            onChange={(event) => setDayCount(day, event.target.value)}
                            sx={{ width: 100 }}
                          />
                        </Grid>
                        {large && (
                          <Grid item xs={7}>
                            {idx === 0 && !readOnly && (
                            <FormControlLabel
                              control={(
                                <Switch
                                  checked={allDaysIdentical}
                                  onChange={(event) => setAllDaysIdentical(event.target.checked)}
                                />
                            )}
                              label="Même chose tous les jours"
                            />
                            )}
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                    {!large && !readOnly && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={allDaysIdentical}
                              onChange={(event) => setAllDaysIdentical(event.target.checked)}
                            />
                            )}
                          label="Même chose tous les jours"
                        />
                      </Grid>
                    )}
                    {formik.errors.details && <Grid item xs={12}><Typography style={{ marginTop: 20 }} color="error">{formik.errors.details}</Typography></Grid>}
                    {(!formik.errors.details && (!reservation || (reservation && reservation.statut !== 'refusee')))
                      && (
                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <Divider />
                        Coût total: {getTotalReservation()}
                      </Grid>
                      )}
                  </Grid>

                </fieldset>
                )}
                {(!readOnly || formik.values.message) && (formik.values.chalet && formik.values.dateDebut && formik.values.nbreNuits) && (
                <Grid item xs={12} sx={{ paddingTop: 0 }}>
                  <TextField
                    id="message"
                    label="Message"
                    fullWidth
                    multiline
                    minRows={2}
                    disabled={readOnly}
                    value={formik.values.message || ''}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>
                )}
              </Grid>
              <Grid container spacing={2} className="actions">
                <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                  <Button
                    sx={{ flex: { xs: 1 } }}
                    variant="outlined"
                    color="info"
                    onClick={() => navigate(-1)}
                  >
                    {reservation && reservation.statut !== 'nouvelle' ? 'Revenir' : 'Annuler'}
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                  {!readOnly && <Button sx={{ flex: { xs: 1 } }} variant="contained" color="primary" type="submit" disabled={!formik.isValid}>{reservation ? 'Enregister' : 'Envoyer'}</Button>}
                </Grid>
                {reservation && user.approbateur && reservation.statut === 'nouvelle' && (
                <>
                  <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                    <Button sx={{ flex: { xs: 1 } }} variant="contained" color="success" onClick={confirmer}>Confirmer</Button>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                    <Button sx={{ flex: { xs: 1 } }} variant="contained" color="error" onClick={openRefusDialog}>Refuser</Button>
                  </Grid>
                </>
                )}
                {reservation && (user.approbateur || user.admin) && (
                <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
                  <Button sx={{ flex: { xs: 1 } }} variant="contained" color="error" onClick={openDeleteDialog}>Supprimer</Button>
                </Grid>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </>
      )}

      <Dialog
        open={deleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Supprimer la demande
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment supprimer la demande de réservation?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Non</Button>
          <Button onClick={handleDelete} autoFocus color="error">
            Oui
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={refusDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Refuser la demande
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez-vous vraiment refuser la demande de réservation?
            <TextField
              fullWidth
              size="small"
              label="Motif du refus"
              onChange={(e) => setMotifRefus(e.target.value)}
              value={motifRefus}
              margin="normal"
              variant="standard"
              required
              error={isEmpty(motifRefus)}
              helperText={isEmpty(motifRefus) ? 'Champ requis' : ''}
            />
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => setRefusDialogOpen(false)}>Non</Button>
          <Button disabled={isEmpty(motifRefus)} onClick={handleRefus} autoFocus color="error">
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReservationForm
